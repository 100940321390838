









































import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import GroupDescriptionCard from '@/components/cards/GroupDescriptionCard.vue'
import ReviewSlider from '@/components/ReviewSlider.vue'
import TeacherCard from '@/components/cards/TeacherCard.vue'
import TeacherCardSlider from '@/components/cards/TeacherCardSlider.vue'
import TrainingProgramCard from '@/components/cards/TrainingProgramCard.vue'
import LinkTelegram from '@/components/_uikit/links/LinkTelegram.vue'
// types
import {
  CourseShopCardResource,
  ProgramTextPart,
} from '@/store/types'

@Component({
  components: {
    GroupDescriptionCard,
    ReviewSlider,
    TeacherCard,
    TeacherCardSlider,
    TrainingProgramCard,
    LinkTelegram,
  },
})
export default class ItemInformation extends Vue {
  @Prop()
  private course!: CourseShopCardResource | null | undefined

  private sliderOptions: any = {
    slidesPerView: 3,
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      600: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 32,
      },
    },
  }

  // Пустая ли программа курса
  private get isEmptyProgram() {
    return this.course && !this.course?.program?.media && this.course?.program?.text.every((item: ProgramTextPart) => !item.description)
  }
}
