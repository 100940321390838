


























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Swiper from 'swiper'
import { EffectCards, Navigation } from 'swiper/modules'

// components
import TeacherModal from '@/components/modals/courses/TeacherModal.vue'
// store
import { TeacherEmojiSlugs, TeacherLargeResource } from '@/store/types'
// utils
import { thumbs } from '@/utils/constants'

@Component({
  components: {
    TeacherModal,
  },
})
export default class TeacherCardSlider extends Vue {
  @Prop({ required: true })
  private teachers!: TeacherLargeResource[]

  @Prop({ required: true })
  private subjectAlias!: string

  private swiper: any = null
  private swiperModal: any = null
  private teacherModalVisible = false
  private currentIndexSlide = 0
  private currentIndexSlideModal = this.currentIndexSlide
  private thumbs = thumbs

  private mounted() {
    this.swiper = new Swiper('.swiper-teachers', {
      cardsEffect: {
        perSlideOffset: 20,
        perSlideRotate: 0,
        slideShadows: false,
      },
      centeredSlides: true,
      effect: 'cards',
      grabCursor: true,
      modules: [EffectCards, Navigation],
      navigation: {
        nextEl: '.teacher-card__slider-arrows_next',
        prevEl: '.teacher-card__slider-arrows_prev',
      },
    })

    this.swiper.on('activeIndexChange', (swiper: any) => this.currentIndexSlideModal = this.currentIndexSlide = swiper.activeIndex)
  }

  private destroyed() {
    this.swiper.destroy()
    if (this.swiperModal) {
      this.swiperModal.destroy()
    }
  }

  private iconSrc(slug: string) {
    return require(`@/assets/images/icons/teachers-description/${slug}.svg`)
  }

  private getEmoji(slug: TeacherEmojiSlugs) {
    return require(`@/assets/images/icons/emoji-subjects/${slug}.svg`)
  }

  @Watch('teacherModalVisible')
  private watchTeacherModalVisible(value: boolean) {
    if (value) {
      setTimeout(() => {
        this.swiperModal = new Swiper('.swiper-teachers-modal', {
          cardsEffect: {
            perSlideOffset: 20,
            perSlideRotate: 0,
            slideShadows: false,
          },
          centeredSlides: true,
          effect: 'cards',
          grabCursor: true,
          initialSlide: this.currentIndexSlide,
          modules: [EffectCards, Navigation],
          navigation: {
            nextEl: '.teacher-card__slider-arrows_next-modal',
            prevEl: '.teacher-card__slider-arrows_prev-modal',
          },
        })

        this.swiperModal.on('activeIndexChange', (swiper: any) => this.currentIndexSlideModal = swiper.activeIndex)
      }, 10)
    } else {
      this.swiperModal.destroy()
      this.currentIndexSlideModal = this.currentIndexSlide
    }
  }
}
