

































import { Component, Prop, Vue } from 'vue-property-decorator'
import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'

// components
import ReviewCard from '@/components/cards/ReviewCard.vue'
import ReviewModal from '@/components/modals/ReviewModal.vue'
// store
import { ReviewResource, ReviewType } from '@/store/types'

@Component({
  components: {
    ReviewCard,
    ReviewModal,
  },
})
export default class ReviewSlider extends Vue {
  @Prop({ required: true })
  private items!: ReviewResource[]

  @Prop({ default: '' })
  private link!: string

  @Prop({ default: true })
  private readonly!: boolean

  @Prop({ required: true })
  private sliderOptions!: any

  @Prop({ default: 'Отзывы' })
  private title!: string

  private swiper: any = null

  private linkReview: ReviewResource = {
    content: '',
    examScore: 0,
    id: 0,
    isCredit: false,
    name: '',
    parentCourse: '',
    photo: {
      id: 0,
      type: 'string',
      filename: 'string',
      url: '',
      size: 0,
    },
    surname: '',
    thumbnail: {
      id: 0,
      type: 'string',
      filename: 'string',
      url: '',
      size: 0,
    },
    type: ReviewType.TEXT,
    videoUrl: '',
    subject: {
      name: '',
      value: 0,
    },
  }

  private showReviewModal = false
  private selectReview: ReviewResource | null = null

  private mounted() {
    this.swiper = new Swiper('.swiper', {
      ...this.sliderOptions,
      navigation: {
        disabledClass: 'v-btn--disabled',
        nextEl: '.slider-header__arrows_next',
        prevEl: '.slider-header__arrows_prev',
      },
      modules: [Navigation],
    })
    this.$bus.$on('swiperUpdate', this.swiperUpdate)
  }

  private destroyed() {
    this.swiper.destroy()
    this.$bus.$off('swiperUpdate', this.swiperUpdate as any)
  }

  private swiperUpdate() {
    this.swiper.update()
  }

  private prevSlide() {
    this.swiper.slidePrev()
  }

  private nextSlide() {
    this.swiper.slideNext()
  }

  private handleShowReview(review: ReviewResource) {
    this.selectReview = review
    this.showReviewModal = true
  }

  private handleCloseModal() {
    this.selectReview = null
  }

  private handleNextSlide() {
    if (this.selectReview) {
      const index = this.items.findIndex(review => review.id === this.selectReview?.id)
      this.selectReview = this.items[index === this.items.length - 1 ? 0 : index + 1]
    }
  }

  private handlePrevSlide() {
    if (this.selectReview) {
      const index = this.items.findIndex(review => review.id === this.selectReview?.id)
      this.selectReview = this.items[index === 0 ? this.items.length - 1 : index - 1]
    }
  }
}
