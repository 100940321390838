




























import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import CourseDescriptionGroupTypeModal from '@/components/modals/courses/CourseDescriptionGroupTypeModal.vue'
import GroupTypeDescription from '@/components/GroupTypeDescription.vue'
import Tag from '@/components/_uikit/Tag.vue'
// store
import { CourseType, GroupShopResource, NameValueResource } from '@/store/types'
// utils
import { subjectsColor } from '@/utils/constants'

@Component({
  components: {
    CourseDescriptionGroupTypeModal,
    GroupTypeDescription,
    Tag,
  },
})
export default class GroupDescriptionCard extends Vue {
  @Prop({ required: true })
  private groups!: GroupShopResource[]

  @Prop({ required: true })
  private courseStart!: string

  @Prop({ required: true })
  private courseEnd!: string

  @Prop({ required: true })
  private courseType!: NameValueResource

  @Prop({ required: true })
  private subjectAlias!: string

  private get isSpecial() {
    return this.courseType.value === CourseType.SPECIAL
  }

  private get subjectColor() {
    return subjectsColor[this.subjectAlias?.trim()]
  }

  private courseDescriptionGroupTypeModalVisible = false
}
