










import { Component, Prop, Vue } from 'vue-property-decorator'

import Tag from '@/components/_uikit/Tag.vue'

@Component({
  components: {
    Tag,
  },
})
export default class Sale extends Vue {
  @Prop({ required: true })
  private subject!: string

  @Prop({ required: true })
  private discount!: number
}
