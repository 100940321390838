















import { Component, Prop, Vue } from 'vue-property-decorator'

// types
import { GroupShopResource } from '@/store/types'

@Component
export default class GroupTypeDescription extends Vue {
  @Prop({ required: true })
  private group!: GroupShopResource

  @Prop({ default: false })
  private isSpecial!: boolean

  private get groupDiff() {
    return this.group.standardDiff ? this.group.standardDiff.split('\n') : ''
  }
}
