










































import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import TeacherModal from '@/components/modals/courses/TeacherModal.vue'
// store
import { TeacherLargeResource } from '@/store/types'
// utils
import { thumbs } from '@/utils/constants'

@Component({
  components: {
    TeacherModal,
  },
})
export default class TeacherCard extends Vue {
  @Prop({ required: true })
  private teachers!: TeacherLargeResource[]

  @Prop({ required: true })
  private subjectAlias!: string

  private teacherModalVisible = false
  private thumbs = thumbs

  private iconSrc(slug: string) {
    return require(`@/assets/images/icons/teachers-description/${slug}.svg`)
  }

  private emoji = require(`@/assets/images/icons/emoji-subjects/${this.teachers[0].emojiSlug}.svg`)
}
