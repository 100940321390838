




























import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import ButtonIconAction from '@/components/_uikit/buttons/ButtonIconAction.vue'
import FilesList from '@/components/FilesList.vue'
// store
import { ProgramCourseShopCardPart } from '@/store/types'

@Component({
  components: {
    ButtonIconAction,
    FilesList,
  },
})
export default class TrainingProgramCard extends Vue {
  @Prop({ required: true })
  private trainingProgram!: ProgramCourseShopCardPart

  @Prop({ required: true })
  private subjectAlias!: string

  private subjectIcon = require(`@/assets/images/icons/subjects/${this.subjectAlias}.svg`)

  private get monthsList(): ProgramCourseShopCardPart {
    return {
      media: this.trainingProgram.media,
      text: this.trainingProgram.text.filter(item => item.description),
    }
  }

  private get listText() {
    return this.monthsList.text.map(item => item.description.split('\n'))
  }

  private show = false
}
